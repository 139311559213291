import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { dynamicImage } from "../utils";
import { Image } from "../AbstractElement";
import { useTranslation } from "react-i18next";
import { useLayoutContext } from "../views";

interface Option {
  label: string;
  value: string;
  data?: {
    [key: string]: any;
  };
}

interface CommonSelectProps {
  selectedOptions: any;
  setSelectedOptions: React.Dispatch<React.SetStateAction<any>>;
  options?: Option[];
  placeholder: any;
  multiSelect: boolean;
  search?: boolean;
  children?: (option: Option) => ReactNode;
  hidePlaceholder?: boolean;
  buttonText?: string;
  buttonClass?: string;
  className?: string;
  onClick?: (value: string) => void;
  onChangeInputSearch?: (value: string) => void;
  inputSearch?: boolean;
  disabled?: boolean;
}

const CommonSelect: React.FC<CommonSelectProps> = ({
  selectedOptions,
  setSelectedOptions,
  options,
  placeholder,
  multiSelect,
  search,
  className,
  children,
  hidePlaceholder,
  buttonText,
  buttonClass,
  onClick,
  onChangeInputSearch,
  inputSearch,
  disabled,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState<string>('');
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useLayoutContext();

  const { t } = useTranslation();

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleInputClick = () => {
    setDropdownOpen(true);
  };

  const handleSelect = (value: string) => {
    if (multiSelect) {
      if (Array.isArray(selectedOptions)) {
        if (selectedOptions.includes(value)) {
          setSelectedOptions(selectedOptions.filter((option) => option !== value));
        } else {
          setSelectedOptions([...selectedOptions, value]);
        }
      }
      if (inputSearch) {
        setDropdownOpen(false);
        setSearchQuery("");
      }
    } else {
      // Handle single-select
      setSelectedOptions(value);
      inputSearch && setSearchQuery(options!.find((option) => option.value === value)!.label);
      setDropdownOpen(false); // Close dropdown on single-select
    }
    document.body.classList.add("no-loader");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    setInputValue(query); // Update state with input value
    if (selectedOptions)
      setSelectedOptions(undefined);
    if (query.trim().length === 0 || inputValue.trim().length === 0 || !dropdownOpen) {
      setDropdownOpen(true); // Open dropdown and show all options
    }

    onChangeInputSearch?.(query);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onClick) {
      toggle();
      onClick(inputValue); // Call onClick if Enter is pressed
    }
  };

  const filteredOptions = useMemo(() =>
    !!(searchQuery.trim())
      ? options?.filter((option) => option.label?.toLowerCase().includes(searchQuery.toLowerCase()))
      : options, [searchQuery, options]
  );

  useEffect(() => {
    if (selectedOptions)
      setInputValue(options?.find((option) => option.value === selectedOptions)?.label || "");
  }, [selectedOptions, options]);

  const getSelectedLabel = () => {
    if (!multiSelect && typeof selectedOptions === "string") {
      const selectedOption = options?.find((option) => option.value === selectedOptions);
      return selectedOption ? selectedOption.label : placeholder;
    }
    return placeholder;
  };


  useEffect(() => {
    if (dropdownOpen && isMobile) {
      document.body.classList.add("dropdown-open");
    } else {
      if (!dropdownOpen && !inputSearch)
        setSearchQuery("");
      document.body.classList.remove("dropdown-open");
    }

    return () => {
      document.body.classList.remove("dropdown-open");
    };
  }, [dropdownOpen, isMobile]);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={`${className ?? ''} ${(multiSelect ? 'select-dropdown-box' : 'filter-dropdown-box')}`}
      disabled={disabled}
    >
      <DropdownToggle caret color="transparent" className={buttonText ? "select-btn" : "select-btn"}>
        {!inputSearch && (
          <>
            {hidePlaceholder ? placeholder : getSelectedLabel()}
            {buttonText && onClick && (
              <button type="button" className={buttonClass} onClick={(e) => {e.stopPropagation(); onClick(inputValue) }}>
                <Image src={dynamicImage("svg/plus.svg")} className="plus-icon" alt="" />
                <span className="create-new">{buttonText}</span>
              </button>
            )}
          </>
        )}
        {inputSearch && (
          <>
            <Input
              innerRef={inputSearchRef}
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onClick={handleInputClick}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoFocus={!isMobile}
              onFocus={() => { inputSearchRef.current && inputSearchRef.current.select() }}
            />
            {buttonText && onClick && (
              <button
                type="button"
                className={buttonClass}
                onClick={(e) => {e.stopPropagation(); onClick(inputValue) }}
              >
                <Image src={dynamicImage("svg/plus.svg")} className="plus-icon" alt="" />
                <span className="create-new">{buttonText}</span>
              </button>
            )}
          </>
        )}
      </DropdownToggle>
      <DropdownMenu className="select-dropdown-menu custom-scroll" container={className?.includes("body") ? "body" : ""}>
        {search && (
          <div className="search-input mb-3">
            <div className="position-relative">
              <Input
                type="text"
                placeholder={t(placeholder ?? "SearchInItems")}
                value={searchQuery}
                onChange={handleSearchChange}
                autoComplete="off"
                autoFocus={!isMobile}
              />
              <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
              <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
            </div>
          </div>
        )}
        <div className="list-box custom-scroll">
          <div>
            {filteredOptions?.map((option) => (
              <DropdownItem toggle={false} key={option.value} onClick={() => handleSelect(option.value)}>
                {multiSelect && (
                  <Input
                    type="checkbox"
                    checked={Array.isArray(selectedOptions) && selectedOptions.includes(option.value)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleSelect(option.value)}
                    autoComplete="off"
                  />
                )}
                {children ? children(option) : option.label}
              </DropdownItem>
            ))}
            {!filteredOptions?.length && buttonText && <p className="dropdown-no-data">{t("NoDataFound")}</p>}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default CommonSelect;
