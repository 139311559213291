import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { router } from "./Routes/Auth";
import { QueryClientProvider, QueryClient } from "react-query";
import { AuthProvider, LayoutProvider, LoaderProvider, StateProvider, ToastProvider } from "./views";
import "./style.scss";
import "./i18n";
import Loader from "./Layout/Loader";
import { useGTM } from "./Hooks";
import { unstable_batchedUpdates } from "react-dom";

interface Props {
    children: React.ReactNode;
}
unstable_batchedUpdates(() => {
    console.error = () => { };
});

class ErrorBoundary extends React.Component<Props> {
    componentDidCatch(error: { message: string | string[] }) {
        if (error.message.includes("ToastContainer")) {
            return;
        }
    }
    render() {
        return this.props.children;
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0
        }
    }
});

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
const tagManagerId = process.env.REACT_APP_TAG_MANAGER_CODE as string;

const Main = () => {

    useGTM(tagManagerId);
    React.useEffect(() => {
        const setVh = () => {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh(); // Set on initial render
        window.addEventListener('resize', setVh); // Update on resize
        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', setVh);
      }, []);
      
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <GoogleOAuthProvider clientId={clientId}>
                    <ToastProvider>
                        <AuthProvider>
                            <LayoutProvider>
                                <StateProvider>
                                    <React.Suspense fallback={<Loader />}>
                                        <LoaderProvider>
                                            <RouterProvider router={router} />
                                        </LoaderProvider>
                                    </React.Suspense>
                                </StateProvider>
                            </LayoutProvider>
                        </AuthProvider>
                    </ToastProvider>
                </GoogleOAuthProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<Main />);